<template>
  <div>
    <v-row>
      <!-- TABULKA S TERMINALY -->
      <v-col
        xs="12"
        sm="7"
        md="7"
        lg="7"
        xl="7"
      >
        <v-card
          :loading="loading"
          :style="`height:${desktop_h - 160}px;padding:10px`"
        >
          <div>
            <!-- LOADING -->
            <template slot="progress">
              <v-progress-linear
                color="deep-purple"
                height="10"
                indeterminate
              ></v-progress-linear>
            </template>
            <!-- TABULKA -->
            <v-simple-table
              style="margin-top:10px;"
              :height="`${desktop_h - 190}px`"
              fixed-header
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      v-for="(column, index) in headers"
                      :key="index"
                      class="text-left"
                    >
                      {{ column.text }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="terminal in table"
                    :key="terminal._id"
                    :class="selected_row === terminal._id ? $vuetify.theme.dark ? 'selected-row-dark-theme' : 'selected-row' : ''"
                    style="cursor:pointer;"
                    @click="terminalDetail(terminal._id)"
                  >
                    <td>
                      {{ terminal.hostname }}
                    </td>
                    <td>
                      {{ terminal.popis }}
                    </td>
                    <td>
                      {{ terminal.stav }}
                    </td>
                    <td>
                      {{ terminal.skupina }}
                    </td>
                    <td>
                      {{ terminal.aktivni }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-card>
      </v-col>
      <!-- DETAIL TERMINALU -->
      <v-col
        xs="12"
        sm="5"
        md="5"
        lg="5"
        xl="5"
      >
        <v-card
          style="padding:20px"
        >
          <br>
          <v-col
            cols="12"
            sm="12"
          >
            <v-text-field
              v-model="dataForm._id"
              style="font-size:14px;padding-left:20px"
              label="Terminál ID:"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="12"
          >
            <v-text-field
              v-model="dataForm.stav_text"
              style="font-size:14px;padding-left:20px"
              label="Stav terminálu:"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="12"
          >
            <v-text-field
              v-model="dataForm.ip_adresa"
              style="font-size:14px;padding-left:20px"
              label="IP adresa:"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="12"
          >
            <v-text-field
              v-model="dataForm.port"
              style="font-size:14px;padding-left:20px"
              label="Port:"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="12"
          >
            <v-text-field
              v-model="dataForm.shutdown_datetime"
              style="font-size:14px;padding-left:20px"
              label="Vypnutí terminálu:"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="12"
          >
            <v-text-field
              v-model="dataForm.shutdown_action"
              style="font-size:14px;padding-left:20px"
              label="Akce vypnutí terminálu:"
            ></v-text-field>
          </v-col>

          <div style="clear:both;"></div>
        </v-card>
      </v-col>
    </v-row>

    <!-- SNACKBAR -->
    <snack-bar
      :snackbar="snackbar"
      :color="snack_color"
      :text="snack_text"
      @close="snackbar=false"
    >
    </snack-bar>
  </div>
</template>

<script>
/* eslint no-underscore-dangle: ["off", { "allow": ["__place"] }] */
import axios from 'axios'
import latinize from 'latinize'
import SnackBar from '@/components/snackbar/index.vue'
import response from '@/mixins/response'

export default {
  components: {
    'snack-bar': SnackBar,
  },
  mixins: [response],
  data() {
    return {
      loading: true,
      loadingD: true,
      desktop_w: 1024,
      desktop_h: 768,
      snackbar: false,
      snack_color: 'red',
      snack_text: '',
      headers: [
        {
          text: 'Hostname',
          align: 'start',
          sortable: true,
          value: 'hostname',
        },
        {
          text: 'Popis',
          align: 'start',
          sortable: true,
          value: 'popis',
        },
        {
          text: 'Stav',
          value: 'stav',
        },
        {
          text: 'Skupina',
          value: 'stavskupina',
        },
        {
          text: 'Aktivni',
          value: 'aktivni',
        },
      ],
      table: {
        _id: '',
        hostname: '',
        popis: '',
        stav: '',
        skupina: '',
        aktivni: '',
      },
      default_dataForm: {
        _id: '',
        stav_text: '',
        ip_adresa: '',
        port: '',
        shutdown_datetime: '',
        shutdown_action: '',
      },
      dataForm: {},
      selected_row: undefined,
      fetch_settings: {
        filter: {
          aktivni: true,
        },
        columns: {
        },
        paging: {
          limit: 999,
        },
        sort: {
          hostname: 1,
        },
      },
    }
  },
  watch: {
    '$store.state.global.filter_search': {
      handler(_newVal) {
        const filter = this.buildFilter(latinize(_newVal).toLowerCase())
        this.fetchTerminalsSeznam(filter)
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    window.addEventListener('resize', this.windowResize)
    this.windowResize()
    this.dataForm = this.default_dataForm
    if (this.table._id) { // jedna sa o UPDATE, nacitaj detail
      this.terminalDetail(this.dataForm._id)
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.windowResize)
  },
  mounted() {
  },
  methods: {
    clear() {
      this.dataForm = this.default_dataForm
    },
    windowResize() {
      this.desktop_w = window.innerWidth
      this.desktop_h = window.innerHeight
    },
    buildFilter(_search) {
      const filter = { // default
        aktivni: true,
      }
      if (_search && _search.length > '') {
        const search = { $regex: `${_search}`, $options: 'i' }
        filter.$or = [
          { hostname: search },
          { stav: search },
          { popis: search },
          { skupina: search },
        ]
      }

      return filter
    },
    fetchTerminalsSeznam(p_filter) {
      this.loading = true
      this.fetch_settings.filter = p_filter || this.fetch_settings.filter
      const body = JSON.stringify(this.fetch_settings)
      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/tool/terminals`,
        data: body,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'post',
      }
      axios(config)
        .then(resp => {
          this.table = resp.data.db_data
          if (this.table.length > 0) {
            const id = this.table[0]._id
            this.terminalDetail(id)
          }
          this.loading = false
          this.fetch_settings.paging.total_pages = resp.data.total_pages
        })
        .catch(err => {
          this.loading = false
          console.error(err)
          this.snack_text = this.responseError(err)
          this.snack_color = 'red'
          this.snackbar = true
        })
    },
    terminalDetail(p_id) {
      for (let i = 0; i < this.table.length; i += 1) {
        if (this.table[i]._id === p_id) {
          this.dataForm = this.table[i]
          if (this.dataForm.shutdown_datetime !== '') {
            this.dataForm.shutdown_datetime = new Date(this.dataForm.shutdown_datetime)
          }

          return
        }
      }
    },
  },
}
</script>

<style>
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
.selected-row {
  background-color: #f2f2f2;
  opacity: 0.9;
  font-weight: bold;
}
.selected-row-dark-theme {
  background-color: #2d2949;
  opacity: 0.9;
  font-weight: bold;
}
</style>
